import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Navigation from 'components/layout/Navigation/index'
import Hero from 'components/layout/Sections/Hero'
import Article from 'components/layout/Sections/Blog/Article'

import { getArticleCategory } from 'utils/getArticleCategory'

const ArticlePage: React.FC<PageProps> = ({ data, pageContext }) => {
  const PAGE = data?.wpAcrtile
  const PAGE_SEO = data?.wpAcrtile?.seo

  console.log(data)

  const ARTICLES = React.useMemo(
    () =>
      data?.allWpAcrtile?.edges?.map(
        ({ node: { date, title, slug, artykul } }) => ({
          date,
          title,
          slug,
          category: getArticleCategory(artykul.articleArticleTyp),
        })
      ),
    []
  )

  const FAQS = PAGE?.artykul?.articleArticleFaq?.map(
    ({ articleArticleFaqSingleDescription, articleArticleFaqSingleTitle }) => ({
      question: articleArticleFaqSingleTitle,
      answer: articleArticleFaqSingleDescription,
    })
  )

  const breadcrumbs = {
    mobile: [
      {
        label: pageContext.category.label,
        link: `/wiadomosci/${pageContext.category.slug}/`,
      },
      {
        label: PAGE.title,
      },
    ],
    desktop: [
      {
        label: 'Home',
        link: '/',
      },
      {
        label: 'Wiadomości',
        link: '/wiadomosci/',
      },
      {
        label: pageContext.category.label,
        link: `/wiadomosci/${pageContext.category.slug}/`,
      },
      {
        label: PAGE.title,
      },
    ],
  }

  React.useEffect(() => {
    if (FAQS && FAQS.length > 0) {
      const script = document.createElement('script')

      const faqTextItems = FAQS?.map(
        ({ question, answer }) =>
          `{"@type":"Question","name":"${question}","acceptedAnswer":{"@type":"Answer","text":"${answer}"}}`
      ).join(',')

      script.type = 'application/ld+json'
      script.text = `{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[${faqTextItems}]}`

      document.head.appendChild(script)
    }
  }, [])

  const heroImg = PAGE?.artykul?.articleHeroImg?.localFile?.childImageSharp
    ?.gatsbyImageData
    ? PAGE?.artykul?.articleHeroImg?.localFile?.childImageSharp?.gatsbyImageData
    : PAGE?.artykul?.articleArticleImg?.localFile?.childImageSharp
        ?.gatsbyImageData

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={heroImg}
        imgDesktop={heroImg}
        headingMobile={PAGE?.title}
        headingDesktop={PAGE?.title}
        backgroundDesktop="white"
        breadcrumbs={breadcrumbs}
      />
      <Article
        title={PAGE?.title}
        date={PAGE?.date}
        category={pageContext.category.label}
        author={PAGE?.author?.node?.name}
        image={{
          src: PAGE?.artykul?.articleArticleImg?.localFile?.childImageSharp
            ?.gatsbyImageData,
          alt: PAGE?.artykul?.articleArticleImg?.altText,
        }}
        content={PAGE?.content}
        articles={ARTICLES}
        faqs={FAQS}
      />
    </Layout>
  )
}

export default ArticlePage

export const query = graphql`
  query ArticlePage($slug: String, $articleType: String) {
    wpAcrtile(slug: { eq: $slug }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      id
      content
      title
      artykul {
        articleHeroImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        articleArticleImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                formats: [JPG, AUTO]
              )
            }
          }
        }
        articleArticleFaq {
          articleArticleFaqSingleDescription
          articleArticleFaqSingleTitle
        }
      }
      date
      author {
        node {
          name
        }
      }
    }
    allWpAcrtile(
      filter: {
        artykul: { articleArticleTyp: { eq: $articleType } }
        slug: { ne: $slug }
      }
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          date
          title
          slug
          artykul {
            articleArticleTyp
          }
        }
      }
    }
  }
`
