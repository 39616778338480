import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazyImage'

import { ImageDataLike } from 'gatsby-plugin-image'

import useLocation from 'hooks/useLocation'

const Wrapper = styled.div<{ marginTopBig: boolean }>`
  position: relative;
  padding: 60px 10px 10px 10px;
  margin-bottom: 30px;
  background-color: #f6f2f2;
  border-radius: 40px;

  ${({ theme }) => theme.media.lg.min} {
    padding: 60px 30px 30px 30px;

    ${({ marginTopBig }) =>
      marginTopBig &&
      css`
        margin: 240px 0 30px;
      `}
  }
`

const Content = styled.article`
  h1,
  h2,
  h3 {
    position: relative;
    color: ${({ theme }) => theme.colors.primary};
    margin-top: 20px;
    padding-top: 20px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 50px;
      height: 8px;
      border-radius: 5px;
      top: 0;
      left: 0;
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  ul {
    list-style: disc;

    li {
      margin-left: 16px;
    }
  }

  a[href='/ogrodzenia/'],
  a[href='/kontakt/'] {
    position: relative;
    display: block;
    width: max-content;

    margin: 30px auto 42px auto;
    padding: 18px 20px;
    background-color: #b19684;
    font-size: 16px;

    background-color: ${({ theme }) => theme.colors.primary};

    font-weight: 500;
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;

    &:hover {
      background-color: #a68973;
    }
  }
  a[href='/kontakt/'] {
    margin: 38px auto 32px auto;
  }

  ${({ theme }) => theme.media.lg.min} {
    a[href='/ogrodzenia/'],
    a[href='/kontakt/'] {
      margin: 28px auto 28px auto;
      padding: 20px 24px;
      font-size: 18px;
    }

    a[href='/kontakt/'] {
      margin: 52px auto 32px auto;
    }
  }
`

const Pin = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px 30px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: 40px;
  border-bottom-right-radius: 5px;
`

const ArticleInfo = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.media.lg.min} {
    justify-content: flex-start;
    gap: 30px;
  }
`

const InnerWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;

    // Lazy Image wrapper
    & > div:first-child {
      height: 550px;
      width: 70%;
      border-radius: 40px;
    }
  }
`

const News = styled.aside``

const NewsLinkWrapper = styled(Link)`
  display: block;
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray500};
`

const Faqs = styled.article``

type Props = {
  title: string
  date?: string
  category?: string
  author?: string
  image: {
    src: ImageDataLike
    alt: string
  }
  content: string
  articles: Array<{
    date: string
    title: string
    slug: string
    category: string
  }>
  faqs: Array<{
    question: string
    answer: string
  }>
}

const Article: React.FC<Props> = ({
  title,
  date,
  category,
  author,
  image,
  content,
  articles,
  faqs,
}) => {
  const pathname = useLocation()
  const isPrivacyPolicy = pathname === '/polityka-prywatnosci'

  useEffect(() => {
    const contentElement = document.getElementById('content')
    const linkElement1 = document.createElement('a')
    const linkElement2 = document.createElement('a')
    linkElement1.href = '/ogrodzenia/'
    linkElement1.innerHTML = 'Poznaj nasze ogrodzenia!'

    linkElement2.href = '/kontakt/'
    linkElement2.innerHTML = 'Skontaktuj się z nami!'

    contentElement?.children[1].insertAdjacentElement('afterend', linkElement1)
    contentElement?.appendChild(linkElement2)
  }, [])

  return (
    <Container>
      <Wrapper marginTopBig={isPrivacyPolicy}>
        {date && author && <Pin>{category}</Pin>}
        <Heading
          as="h2"
          size={28}
          weight={600}
          themecolor="primary"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {date && author && (
          <ArticleInfo>
            <Text weight={500}>{author}</Text>
            <div>|</div>
            <Text weight={500}>{new Date(date).toLocaleDateString()}</Text>
            <div>|</div>
            <Text weight={500}>{category}</Text>
          </ArticleInfo>
        )}
        <InnerWrapper>
          <LazyImage src={image.src} alt={image.alt} />
          <News>
            <Heading
              as="h3"
              size={24}
              weight={600}
              themecolor="primary"
              transform="uppercase"
            >
              {category}
            </Heading>

            {articles
              ?.slice(0, 7)
              ?.map(
                ({
                  date: newsDate,
                  title: newsTitle,
                  slug: newsSlug,
                  category: newsCategory,
                }) => (
                  <NewsLinkWrapper
                    to={`/wiadomosci/${newsCategory}/${newsSlug}/`}
                  >
                    <Text themecolor="primary">
                      {new Date(newsDate).toLocaleDateString()}
                    </Text>
                    <Text size={16}>{newsTitle}</Text>
                  </NewsLinkWrapper>
                )
              )}
          </News>
        </InnerWrapper>
        <Content id="content" dangerouslySetInnerHTML={{ __html: content }} />
        {faqs && faqs.length > 0 ? (
          <Faqs>
            <Heading
              as="h3"
              size={28}
              weight={600}
              themecolor="primary"
              transform="uppercase"
            >
              FAQ:
            </Heading>
            {faqs?.map((faqSingle) => (
              <div key={faqSingle.question}>
                <p>
                  <strong
                    dangerouslySetInnerHTML={{ __html: faqSingle.question }}
                  />
                </p>
                <p dangerouslySetInnerHTML={{ __html: faqSingle.answer }} />
              </div>
            ))}
          </Faqs>
        ) : null}
      </Wrapper>
    </Container>
  )
}

export default Article
